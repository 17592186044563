import React, { useState, useEffect } from 'react';

const ScrollContext = React.createContext();

export function ScrollProvider({ children }) {
  const [scrollPosition, setScrollPosition] = useState(0);

  return (
    <ScrollContext.Provider value={[scrollPosition, setScrollPosition]}>
      {children}
    </ScrollContext.Provider>
  );
}

export default ScrollContext;
