import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --black: #000000;
    --white: #ffffff;   
    --padding-xsmall: 1rem; 
    --padding-small: 2rem;
    --padding-medium: 4rem;
    --padding-large: 6rem;
    --border: 1px solid var(--black);
  }

  @media (max-width: 700px) {
    :root {
      --padding-medium: 1.5rem;
    }  
  }

  html, body {
    height: 100%;
    margin: 0;
  }

  html {
    font-size: 10px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    background: var(--white);
    color: var(--black);
    font-size: clamp(2rem, 1.6667vw, 2.4rem);
    margin: 0;
    padding: 0;
  }

  img {
    max-width: 100%;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    display: block;
    color: inherit;
    
    &:visited {
      color: inherit;
    }
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  .btn {
    background: var(--black);
    color: var(--white) !important;
    display: inline-block;
    border-radius: 14px;
    font-size: 1.6rem;
    border: none;
    cursor: pointer;
    padding: 10px 28px;
  }
`;

export default GlobalStyles;
