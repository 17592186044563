import { Link } from 'gatsby';
import React from 'react';
import { connectStateResults, Hits, Index } from 'react-instantsearch-dom';

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits;

  return hitCount > 0 ? (
    <div className="HitCount">
      {hitCount} result{hitCount !== 1 ? `s` : ``}
    </div>
  ) : (
    <div className="HitCount">No results</div>
  );
});

const PageHit = ({ hit }) => (
  <div>
    {console.log(hit)}
    <Link to={`/${hit.slug}`}>
      <h4>{hit.name}</h4>
    </Link>
  </div>
);

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount />
    <Hits className="Hits" hitComponent={PageHit} />
  </Index>
);

const SearchResult = ({ indices, className }) => (
  <div className={className}>
    {indices.map((index) => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </div>
);

export default SearchResult;
