import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import ArchivivoImg from '../assets/images/archivivo.png';
import Search from './search';
import Burger from './burger/Burger';

const searchIndices = [{ name: `Projects` }];

const StyledNav = styled.nav`
  display: flex;
  position: relative;
  align-items: center;
  padding: 0 var(--padding-medium);
  height: 7.5rem;
  border-bottom: var(--border);

  a.archivivo {
    height: 100%;
    margin: auto;

    @media (max-width: 700px) {
      display: none;
    }

    img {
      width: clamp(13rem, 9vw, 32rem);
      max-height: 100%;
    }
  }

  ul {
    display: flex;
    margin-right: var(--padding-medium);

    li:last-of-type {
      margin-left: var(--padding-medium);
    }

    @media (max-width: 700px) {
      transition: transform 400ms ease-in-out;
      width: 100%;
      height: 100vh;
      background: var(--white);
      position: fixed;
      top: 0;
      right: 0;
      z-index: 99999;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 0;
      transform: translateX(100%);

      &.active {
        transform: translateX(0);
      }

      li {
        margin-bottom: 1rem;

        &:last-of-type {
          margin-left: 0;
        }
      }
    }
  }
`;

export default function Nav() {
  const [open, setOpen] = useState(false);

  return (
    <StyledNav>
      <h1>
        <Link to="/">Michele Sambin</Link>
      </h1>
      <Link to="/" className="archivivo">
        <img src={ArchivivoImg} alt="archivivo" />
      </Link>
      <Burger open={open} setOpen={setOpen} />
      <ul className={open ? 'active' : null}>
        <li>
          <Link to="/bio" onClick={() => setOpen(!open)}>
            Bio
          </Link>
        </li>
        <li>
          <Link to="/contact" onClick={() => setOpen(!open)}>
            Contact
          </Link>
        </li>
      </ul>
      <Search indices={searchIndices} />
    </StyledNav>
  );
}
