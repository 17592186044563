import React from 'react';
import Layout from './src/components/Layout';
import { ScrollProvider } from './src/components/ScrollContext';

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

export function wrapRootElement({ element }) {
  return <ScrollProvider>{element}</ScrollProvider>;
}
