import styled, { css } from 'styled-components';
import SearchResult from './search-result';

const Popover = css`
  max-height: 80vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  z-index: 99;
  right: 0;
  top: calc(100% + 1px);
  width: 80vw;
  max-width: 35rem;
  box-shadow: -1px 1px 2px;
  padding: 1em;
  background: var(--white);
  /* border-top: 1px solid var(--black); */
`;

export default styled(SearchResult)`
  display: ${(props) => (props.show ? `block` : `none`)};
  ${Popover}

  .HitCount {
    display: flex;
    justify-content: flex-end;
  }

  .Hits {
    ul {
      list-style: none;
      margin-left: 0;
      margin: 0;
      display: block;
    }

    li.ais-Hits-item {
      margin: 0 0 1em;

      a {
        color: var(--text);

        h4 {
          margin-bottom: 0.2em;
        }
      }
    }
  }
`;
