import styled from 'styled-components';

export const StyledBurger = styled.button`
  z-index: 999999;
  height: 2rem;
  width: 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;

  div {
    width: 100%;
    height: 3px;
    display: block;
    background: var(--black);
  }

  @media (min-width: 700px) {
    display: none;
  }
`;
